










































































































import { defineComponent, computed, toRefs } from '@vue/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';
import { onSSR } from '@vue-storefront/core';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';
import { SCHEMA_ITEM_PROP, SCHEMA_ITEM_TYPE } from '~/constants/seo';
import {
  useProductShipping,
  useProductPrice,
  useStoreVsfExtended,
  useVolumeDiscounts,
  useProductAvailabilityDate,
  useRouteBestPriceGuarantee,
  useExtraGuaranteeExcluded,
} from '~/composables';
import { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import ProductCardBadgeWrapper from '~/components/organisms/Product/ProductCardBadgeWrapper.vue';
import PriceDisclaimer from '~/components/molecules/Product/PriceDisclaimer.vue';
import AddToCart from '~/components/molecules/Product/AddToCart.vue';
import VolumeDiscounts from '~/components/molecules/Product/VolumeDiscount/VolumeDiscounts.vue';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import VatInfo from '~/components/atoms/Product/VatInfo.vue';
import StockInformation from '~/components/molecules/Product/StockInformation.vue';
import LowestPriceGuaranteed from '~/components/atoms/Product/LowestPriceGuaranteed.vue';
import { isTestProduct } from '~/helpers/product/isTestProduct';
import isString from '~/helpers/dataStructure/isString';
import { MAIN_ADD_TO_CART_ID } from '~/constants/floatingAddToCart';
import { DEFAULT_VOLUME_DISCOUNT_GROUP } from '~/constants/volumeDiscounts';
import AlternativeProduct from '~/components/molecules/Product/AlternativeProduct/AlternativeProduct.vue';
import RequestCustomQuote from '~/components/molecules/Product/details/RequestCustomQuote/RequestCustomQuote.vue';

export default defineComponent({
  name: 'ProductPricingSection',
  components: {
    AddToCart,
    ProductCardBadgeWrapper,
    LazyHydrate,
    PriceDisclaimer,
    VatInfo,
    EnergyRating: () => import(/* webpackChunkName: "EnergyRating" */
      '~/components/molecules/Product/EnergyRating.vue'),
    ProductSummaryDetails: () => import(/* webpackChunkName: "ProductSummaryDetails" */
      '~/components/organisms/Product/ProductSummaryDetails.vue'),
    VolumeDiscounts,
    StockInformation,
    ProductCardDelivery: () => import(/* webpackChunkName: "ProductCardDelivery" */
      '~/components/atoms/Product/ProductCardDelivery.vue'),
    LowestPriceGuaranteed,
    LimitedTimeOffer: () => import(/* webpackChunkName: "LimitedTimeOffer" */
      '~/components/atoms/Product/LimitedTimeOffer.vue'),
    ExtraGuaranteeInfo: () => import(/* webpackChunkName: "ExtraGuaranteeInfo" */
      '~/components/atoms/ExtraGuaranteeInfo/ExtraGuaranteeInfo.vue'),
    AlternativeProduct,
    RequestCustomQuote,
  },
  props: {
    product: {
      type: Object as PropType<ProductVariant>,
      required: true,
    },
    attributesLocalized: {
      type: Object as PropType<ProductProcessedAttributes>,
      required: true,
    },
    productAdjustedForCart: {
      type: Object,
      required: true,
    },
    availableQuantity: {
      type: Number,
      required: true,
    },
    stock: {
      type: Number,
      required: true,
    },
    loadingStocks: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { product } = toRefs(props);

    const { getPriceInformation } = useProductPrice();
    const { isChangeTaxRateEnabled } = useStoreVsfExtended();

    const isProductReadyToShip = computed(() => props.availableQuantity > 0);

    const {
      isFreightFeeApplied,
      isParcelFeeApplied,
      shippingFeeDetails,
      deliveryDate,
    } = useProductShipping(product);

    const {
      productPrice,
      finalPrice,
      priceBeforeDiscounts,
    } = getPriceInformation(product);

    const { isGroupActive, load: loadVolumeDiscount, volumeDiscountGroups } = useVolumeDiscounts();

    const releaseDate = computed(() =>
      getProductAttributesExtended(
        props.product,
        {
          attributeNames: [PRODUCT_ATTRIBUTES.RELEASE_DATE],
        },
      )[PRODUCT_ATTRIBUTES.RELEASE_DATE]);

    const testProductIdentifier = computed(() => isTestProduct(product.value.sku));
    const releaseDateString = computed(() => isString(releaseDate.value) ? releaseDate.value : '');

    const { calculateAvailabilityDate } = useProductAvailabilityDate(releaseDateString.value);
    const availabilityDate = computed(() => testProductIdentifier.value ? calculateAvailabilityDate() : '');

    const { priceGuaranteeUrl } = useRouteBestPriceGuarantee();

    const isInStock = computed(() => props.stock > 0);
    const showDeliveryTiming = computed(() => (deliveryDate.value && isInStock.value) || testProductIdentifier.value);
    const hasEnergyRating = computed(() => props.attributesLocalized?.energyRating);
    const volumeDiscountGroup = computed(() =>
      getProductAttributesExtended(
        props.product,
        { attributeNames: [PRODUCT_ATTRIBUTES.VOLUME_DISCOUNT_GROUP] },
      )[PRODUCT_ATTRIBUTES.VOLUME_DISCOUNT_GROUP] || DEFAULT_VOLUME_DISCOUNT_GROUP,
    );

    const { isGuaranteeExcluded } = useExtraGuaranteeExcluded();
    const showExtraGuaranteeInfo = computed(() => {
      return isInStock.value && !isGuaranteeExcluded(product.value);
    });

    onSSR(async () => {
      await loadVolumeDiscount(volumeDiscountGroup.value);
    });

    return {
      SCHEMA_ITEM_TYPE,
      SCHEMA_ITEM_PROP,
      priceBeforeDiscounts,
      isFreightFeeApplied,
      isParcelFeeApplied,
      shippingFeeDetails,
      isProductReadyToShip,
      deliveryDate,
      productPrice,
      finalPrice,
      isChangeTaxRateEnabled,
      isGroupActive,
      availabilityDate,
      isInStock,
      testProductIdentifier,
      showDeliveryTiming,
      priceGuaranteeUrl,
      hasEnergyRating,
      MAIN_ADD_TO_CART_ID,
      volumeDiscountGroups,
      volumeDiscountGroup,
      showExtraGuaranteeInfo,
    };
  },
});
